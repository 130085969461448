import _defineProperty from "E:/web_project/vue_project/hotel_project/node_modules/_@babel_runtime@7.14.0@@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { regionData, CodeToText } from "element-china-area-data";
import BMap from "BMap";
import { post_hotel_data, get_info as _get_info, uploadImgl_ist } from "../../api/index";
import tinymce from "tinymce/tinymce"; // tinymce默认hidden，不引入不显示

import Editor from "@tinymce/tinymce-vue";
import "tinymce/skins/content/default/content.css";
import "tinymce/themes/silver/theme.min.js";
import "tinymce/icons/default/icons";
import "tinymce/icons/default/icons.min.js";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/preview";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
export default {
  name: "permission",
  components: {
    Editor: Editor
  },
  data: function data() {
    var _this = this,
        _ref;

    return _ref = {
      title: "新增酒店",
      disabled: false,
      isabled: false,
      content: " gdgg",
      // 添加酒店
      addhotelData: {
        type: "add",
        hotel_name: this.hotel_name,
        //酒店名称
        phone: this.phone,
        //联系方式
        desc: this.desc,
        //酒店简介
        province_id: "",
        //省id
        city_id: "",
        //市id
        county_id: "",
        //区id
        province: "",
        //省
        city: "",
        //市
        county: "",
        //区
        lon: "",
        //坐标经度
        lat: "",
        //坐标纬度
        image_url: [],
        address: this.address,
        //详细地址
        open_time: this.open_time,
        //开业时间
        decorating_time: this.decorating_time,
        //装修时间
        service_more: this.service_more,
        //扩展项
        list_order: this.list_order,
        //排序
        status: this.status,
        //是否正常营业
        facility_id_arr: []
      },
      editorOption: {},
      permissionBeans: [],
      options: regionData,
      // 表单验证
      rules: {
        hotel_name: [{
          required: true,
          message: "请输入酒店名称",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入酒店简介",
          trigger: "blur"
        }],
        phone: [{
          required: true,
          message: "请输入手机号",
          trigger: "blur"
        }, {
          pattern: /^1[3|5|7|8|9]\d{9}$/,
          message: "请输入正确的号码格式",
          trigger: "change"
        }],
        address: [{
          required: true,
          message: "请输入详细地址",
          trigger: "blur"
        }],
        open_time: [//开业时间
        {
          type: "date",
          required: true,
          message: "请选择日期",
          trigger: "change"
        }],
        decorating_time: [// 装修时间
        {
          type: "date",
          required: true,
          message: "请选择时间",
          trigger: "change"
        }],
        address_di: [{
          required: false,
          message: "请选择地址",
          trigger: "change"
        }]
      },
      dialogImageUrl: "",
      dialogVisible: false
    }, _defineProperty(_ref, "disabled", false), _defineProperty(_ref, "init", {
      language_url: "/tinymce/langs/zh_CN.js",
      language: "zh_CN",
      skin_url: "/tinymce/skins/lightgray",
      height: 300,
      plugins: "link lists image code table colorpicker textcolor wordcount contextmenu",
      toolbar: "bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat",
      branding: false,
      paste_word_valid_elements: "*[*]",
      // word需要它
      paste_data_images: true,
      // 粘贴的同时能把内容里的图片自动上传，非常强力的功能
      paste_convert_word_fake_lists: false,
      // 插入word文档需要该属性
      // 配置图片上传的功能
      images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
        _this.handleImgUpload(blobInfo, success, failure);
      }
    }), _ref;
  },
  methods: {
    // 三级联动
    addressChange: function addressChange(arr) {
      console.log(arr[0], arr[1], arr[2]);
      console.log(CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]]);
      this.addhotelData.province_id = arr[0];
      this.addhotelData.city_id = arr[1];
      this.addhotelData.county_id = arr[2];
      this.addhotelData.province = CodeToText[arr[0]];
      this.addhotelData.city = CodeToText[arr[1]];
      this.addhotelData.county = CodeToText[arr[2]];
    },
    // 地理位置
    createMap: function createMap() {
      /* eslint-disable */
      // 创建Map实例
      var map = new BMap.Map("map"); // 初始化地图,设置中心点坐标和地图级别

      map.centerAndZoom(new BMap.Point(116.404, 39.915), 11); //添加地图类型控件

      map.addControl(new BMap.MapTypeControl({
        mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
      })); // 设置地图显示的城市 此项是必须设置的

      map.setCurrentCity("北京"); //开启鼠标滚轮缩放

      map.enableScrollWheelZoom(true);
      /* eslint-enable */
    },
    changeStartTime: function changeStartTime(time) {
      console.log("starttime", time);
      this.formdata2.travelStartTime = time;
    },
    // 监听输入框value,获取详细位置
    search: function search(event) {
      var that = this;
      var map = new BMap.Map("map");
      map.clearOverlays(); //清除地图上所有覆盖物

      function myFun() {
        var pp = local.getResults().getPoi(0).point; //获取第一个智能搜索的结果

        map.centerAndZoom(pp, 18);
        map.addOverlay(new BMap.Marker(pp)); //添加标注
      }

      var local = new BMap.LocalSearch(map, {
        //智能搜索
        onSearchComplete: myFun
      });
      local.search(event);
      map.addEventListener("click", function (e) {
        console.log("点击位置经纬度：" + e.Ag.lng + "," + e.Ag.lat);
        that.$message.success("当前坐标地址为" + e.Ag.lng + "," + e.Ag.lat);
        that.addhotelData.lon = e.Ag.lng;
        that.addhotelData.lat = e.Ag.lat;
      });
    },
    // 获取酒店信息
    get_info: function get_info(id) {
      var _this2 = this;

      var get_infoData = {
        type: "hotel",
        id: id,
        token: this.addhotelData.token
      };
      var map = new BMap.Map("map");
      console.log(map); // 设置地图显示的城市 此项是必须设置的

      _get_info(get_infoData).then(function (res) {
        console.log(res);
        _this2.addhotelData.hotel_name = res.data.list.hotel_name;
        _this2.addhotelData.phone = res.data.list.phone;
        _this2.addhotelData.desc = res.data.list.desc;
        _this2.addhotelData.open_time = res.data.list.open_time;
        _this2.addhotelData.decorating_time = res.data.list.decorating_time;
        _this2.addhotelData.province = res.data.list.province;
        _this2.addhotelData.city = res.data.list.city;
        _this2.addhotelData.county = res.data.list.county;
        _this2.addhotelData.address = res.data.list.address;
        _this2.addhotelData.is_parking = res.data.list.is_parking;
        _this2.addhotelData.is_wifi = res.data.list.is_wifi;
        _this2.addhotelData.is_checkroom = res.data.list.is_checkroom;
        _this2.addhotelData.is_restaurant = res.data.list.is_restaurant;
        _this2.addhotelData.list_order = res.data.list.list_order;
        _this2.addhotelData.status = res.data.list.status;
        _this2.addhotelData.facility_id_arr = res.data.list.facility_id_arr;
        _this2.addhotelData.image_url = res.data.list.image_url;
        map.centerAndZoom(res.data.list.address);
      });
    },
    // 表单提交
    submitForm: function submitForm(formName) {
      var _this3 = this;

      if (this.$route.query.id) {
        console.log("编辑");
        var editData = {
          type: "edit",
          id: this.$route.query.id,
          hotel_name: this.addhotelData.hotel_name,
          //酒店名称
          phone: this.addhotelData.phone,
          //联系方式
          desc: this.addhotelData.desc,
          //联系方式
          province_id: "",
          //省id
          city_id: "",
          //市id
          county_id: "",
          //区id
          province: "",
          //省
          city: "",
          //市
          county: "",
          //区
          lon: "",
          //坐标经度
          lat: "",
          //坐标纬度
          address: this.addhotelData.address,
          //详细地址
          open_time: this.addhotelData.open_time,
          //开业时间
          decorating_time: this.addhotelData.decorating_time,
          //装修时间
          service_more: this.addhotelData.service_more,
          //扩展项
          list_order: this.addhotelData.list_order,
          //排序
          status: this.addhotelData.status //是否正常营业

        };
        console.log(editData);
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            post_hotel_data(editData).then(function (res) {
              if (res.code == 1) {
                _this3.$message.success(res.msg);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        console.log(this.addhotelData);
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            post_hotel_data(_this3.addhotelData).then(function (res) {
              if (res.code == 1) {
                _this3.$message.success(res.msg);
              } else {
                _this3.$message.error(res.msg);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    back: function back() {
      this.$router.go(-1); //返回上一层
    },
    //上传图片
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.loading = false;
      this.addhotelData.image_url.push(res.data.filepath);
      this.$message.success(res.msg);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this4 = this;

      this.loading = true;
      this.progressData = 0;
      var timer2 = setInterval(function () {
        _this4.progressData = _this4.progressData + 1;

        if (_this4.progressData >= 100) {
          clearInterval(timer2);
        }
      }, 200);
      var isJPG = file.type === 'image/jpeg/jpg';
      var isLt5M = file.size / 1024 / 1024 < 10; // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
      // }
      // if (!isLt5M) {
      //   this.$message.error("上传头像图片大小不能超过 10MB!");
      // }

      return isLt5M;
    },
    getmenulist: function getmenulist() {
      var facilitydata = {
        type: "facility",
        belong: "1"
      };

      _get_info(facilitydata).then(function (res) {
        console.log(res);

        if (res.code == 1) {// this.permissionBeans = res.data.list
        } else {
          return false;
        }
      });
    },
    to_data: function to_data() {
      console.log(this.tinymceHtml);
      var Base64 = {
        encode: function encode(str) {
          return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }));
        },
        decode: function decode(str) {
          return decodeURIComponent(atob(str).split("").map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(""));
        }
      }; // 将富文本内容专程base64编码，这个用于上传到服务存储到数据库中

      var encoded = Base64.encode(this.addhotelData.desc); // 将富文本的base64编码 转换成原来的格式，这个用于将数据库中的富文本展示在界面上

      var decoded = Base64.decode(encoded);
      console.log(encoded);
      console.log(decoded);
    },
    // 用于上传图片的，后端需要提供好上传接口
    handleImgUpload: function handleImgUpload(blobInfo, success, failure) {
      var formdata = new FormData();
      formdata.set("upload_file", blobInfo.blob());
      var new_headers = {
        headers: this.headers
      };
      var upload_url = process.env.BASE_API + "/website/uploadfile";
      axios.post(upload_url, formdata, new_headers).then(function (res) {
        // console.log(res.data.data)
        success(res.data.data[0]);
      }).catch(function (res) {
        failure("error");
      });
    }
  },
  created: function created() {
    this.getmenulist();
  },
  mounted: function mounted() {
    tinymce.init({});
    this.createMap();

    if (this.$route.query.id) {
      var id = this.$route.query.id; // 获取酒店信息，编辑使用

      this.get_info(id); // this.addhotelData.hotel_name = data

      this.title = "编辑酒店";
      document.title = "编辑酒店";
    }
  },
  computed: {}
};